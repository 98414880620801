exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-partners-and-funds-js": () => import("./../../../src/pages/partners-and-funds.js" /* webpackChunkName: "component---src-pages-partners-and-funds-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-read-more-js": () => import("./../../../src/pages/readMore.js" /* webpackChunkName: "component---src-pages-read-more-js" */),
  "component---src-pages-started-js": () => import("./../../../src/pages/started.js" /* webpackChunkName: "component---src-pages-started-js" */)
}

